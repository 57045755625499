import logo from './logo.png';
import './App.css';
import { env } from './env'
import React, { useState, useEffect } from 'react';


function App() {
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [userEmail, setUserEmail] = useState(null);
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState([]);
  const [sortOption, setSortOption] = useState('id-desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  
  const statusOptions = Array.from(new Set(items.map((item) => item.status)));
  const email = sessionStorage.getItem('userEmail');
  const token = sessionStorage.getItem('userToken');
  
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const statusMapping = {
    0: {label: 'offen', color: 'red', default: true},
    10: {label: 'in Bearbeitung', color: 'yellow', default: false},
    100: {label: 'geschlossen', color: 'green', default: false},
  };

  useEffect(() => {
    setUserEmail(email);
  
    const fetchData = () => {
      fetch('/v1/tickets', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok (status: ${response.status})`);
          }
          return response.json();
        })
        .then((data) => {
          const transformedData = data.map((item, index) => ({
            id: index + 1,
            title: item.title,
            status: item.status,
            description: item.description,
            ticketId: item.ticketId,
            customerId: item.customerId,
            creatorUserId: item.creatorUserId,
            chatroomId: item.chatroomId,
            agentUserId: item.agentUserId
          }));
          setItems(transformedData);


        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    };
  
    const fetchDataWithInterval = () => {
      fetchData();
      const refreshInterval = env.REACT_APP_REFRESH_INTERVAL_SEC ? env.REACT_APP_REFRESH_INTERVAL_SEC * 1000 : 30000; // transform s to ms
      const refreshTimer = setInterval(fetchData, refreshInterval);
      return () => {
        clearInterval(refreshTimer);
      };
    };

    const delay = 100;
    setTimeout(fetchDataWithInterval, delay);
  }, [token]);

  const toggleDescriptionExpansion = (itemId) => {
    if (expandedDescriptions.includes(itemId)) {
      setExpandedDescriptions(expandedDescriptions.filter((id) => id !== itemId));
    } else {
      setExpandedDescriptions([...expandedDescriptions, itemId]);
    }
  };

  const acceptTicket = (ticketId, chatroomId) => {
    fetch(`/v1/tickets/${ticketId}/accept`, {
      method: 'POST',
      headers: headers,
      body: {}
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log(`Ticket ${ticketId} accepted successfully.`);
      const messengerUrl = env.REACT_APP_MESSENGER_URL;
      if(messengerUrl === undefined) {
        alert("Fehler beim automatischen Aufruf des Messengers. Bitte versuchen Sie, das Ticket manuell im Messenger zu öffnen.");
        window.location.reload();
      }
      const ticketUrl = `${messengerUrl}#/room/${chatroomId}`;
      const windowName = `ticketWindow${ticketId}`;
  
      // Try to open a new tab or switch to an existing tab with the same name.
      const ticketWindow = window.open(ticketUrl, windowName);

      if (ticketWindow) {
        // If the window is successfully opened or already exists, attempt to focus it.
        ticketWindow.focus();
        window.location.reload();
      } else {
        console.error('Failed to open or switch to the ticket window');
      }
    })
    .catch((error) => {
      alert("Es gab ein Problem beim Bearbeiten dieses Vorgangs. Seite neu laden.");
      window.location.reload();
      console.error(`Error accepting ticket ${ticketId}:`, error);
  });
}

const closeTicket = (ticketId) => {
  if (window.confirm(`Sind Sie sicher, dass Sie Ticket Nr. ${ticketId} schließen möchten?`)) {
    fetch(`/v1/tickets/${ticketId}/close`, {
      method: 'POST',
      headers: headers,
      body: {}
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log(`Ticket ${ticketId} closed successfully.`);
      window.location.reload();
    })
    .catch((error) => {
      alert("Es gab ein Problem beim Annehmen dieses Vorgangs. Seite neu laden.");
      window.location.reload();
      console.error(`Error accepting ticket ${ticketId}:`, error);
  });
  }
}


// filter and sort data into filteredAndSortedItems
const filteredAndSortedItems = items
  .filter((item) => {
    const { title, description, status, customerId, ticketId } = item;
    const queryLowerCase = query.toLowerCase();
    const statusFilterMatch = statusFilter.length === 0 || statusFilter.includes(status);
    const queryMatch =
      title.toLowerCase().includes(queryLowerCase) ||
      description.toLowerCase().includes(queryLowerCase) ||
      customerId.toString().includes(queryLowerCase) ||
      ticketId.toString().includes(queryLowerCase) ||
      status.toString().toLowerCase().includes(queryLowerCase);

    // include the item if it matches status and query conditions
    return statusFilterMatch && queryMatch;
  })


  //actual sort function
  .sort((a, b) => {
  if (sortOption === 'id-asc') {
      return a.ticketId - b.ticketId;
    } else if (sortOption === 'id-desc') {
      return b.ticketId - a.ticketId;
    } else if (sortOption === 'title-asc') {
      return a.title.localeCompare(b.title);
    } else if (sortOption === 'title-desc') {
      return b.title.localeCompare(a.title);
    } else if (sortOption === 'cust-asc') {
      return a.customerId.localeCompare(b.customerId);
    } else if (sortOption === 'cust-desc') {
      return b.customerId.localeCompare(a.customerId);
    }
    return 0;
  });

  //calculate pagination and set current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAndSortedItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {/* header incl. logo, title and user mail*/}
      <div className="header">
        <div className="logo">
          <img src={logo} alt="Logo" width={100}/>
        </div>
          <span className="title">|  Support Tickets  |  </span>
          {`Nutzer: ${userEmail}`}
      </div>

      <div>

      {/*search/filter/sort section above item list*/}
        <div className="filter-section">
          {/*search*/}
          <div className="search-wrapper">
            <input
              className="search-field"
              type="text"
              placeholder="Suche Vorgänge..."
              value={query}
              onChange={(e) => {setQuery(e.target.value); setCurrentPage(1)}}
            />
          </div>

          {/*filter by status*/}
          {statusOptions.length === 0 ? (<></>) : (
            <div className="status-wrapper">
              <label>Status: </label>
              <div className="checkbox-label">
                {statusOptions.map((status, index) => (
                 <label key={index}>
                 <input
                   className="status-checkbox"
                   type="checkbox"
                   value={statusMapping[status]}
                   checked={statusFilter.includes(status)}
                   onChange={() => {
                     setCurrentPage(1);
                     const updatedStatusFilter = statusFilter.includes(status)
                       ? statusFilter.filter((item) => item !== status)
                       : [...statusFilter, status];
                     setStatusFilter(updatedStatusFilter);
                   }}
                 />
                 {" " + statusMapping[status].label}
               </label>
                ))}
              </div>
            </div> 
            )}

          {/*sort by*/}
          <div>
            <label htmlFor="sortOption">Sortieren nach: </label>
            <select
              className="sort-field"
              defaultValue={"id-desc"}
              value={sortOption}
              onChange={(e) => {setSortOption(e.target.value); setCurrentPage(1)}}
            >
              <option value="id-asc">Ticket-Nr. (aufsteigend)</option>
              <option value="id-desc">Ticket-Nr. (absteigend)</option>
              <option value="title-asc">Betreff (A-Z)</option>
              <option value="title-desc">Betreff (Z-A)</option>
              <option value="cust-asc">Kunden-Nr. (aufsteigend)</option>
              <option value="cust-desc">Kunden-Nr. (absteigend)</option>
            </select>
          </div>
        </div>

        {/*display (filtered) items*/}
        <div className='item-list'>
          {currentItems.length === 0 ? (
            <p>Keine Vorgänge gefunden. Bitte prüfen Sie Ihre Anfrage und Filteroptionen.</p>
          ) : (
            <div>
        {currentItems.map((item) => (
          <div key={item.id} className='list-item-wrapper'>
            <div className={`list-item ${expandedDescriptions.includes(item.id) ? 'expanded' : ''}`}>
              <div className="list-item-content">
                <b>Ticket #{item.ticketId} - Kunde: {item.customerId}</b>
                <div><b>Betreff:</b> {item.title}</div>
                <div onClick={() => toggleDescriptionExpansion(item.id)} 
                  className={`description ${expandedDescriptions.includes(item.id) ? 'expanded' : ''}`}
                >
                  <b>Beschreibung:</b> {item.description}
                </div>
                <div className={statusMapping[item.status].color}><b>{statusMapping[item.status].label.toUpperCase()}</b> 
                {item.status === 10 ? (<span> durch <b>{item.agentUserId.split(":")[0]}</b></span>) : (<></>)}</div>
              </div>
              <div className="list-buttons">
              <button 
                    className={`list-button ${item.status === 10 || item.status === 100 ? 'disabled-button' : ''}`}
                    disabled={item.status === 10 || item.status === 100}
                    onClick={() => acceptTicket(item.ticketId, item.chatroomId)}
                  >
                <b>Ticket bearbeiten</b></button>
              <button 
                    className={`list-button alert ${item.status === 100 ? 'disabled-button' : ''}`}
                    disabled={item.status === 100}
                    onClick={() => closeTicket(item.ticketId)}
                  >
                <b>Ticket schließen</b></button>
              </div>
            </div>
          </div>
        ))}
      </div>
          )}
        </div>

        {/*footer*/}
        <div className="footer">
          <div className="pagination">
            <button
              className="page-link"
              disabled={currentPage === 1}
              onClick={() => paginate(currentPage - 1)}
            >
            &laquo;
            </button>
            <span className="page-info">
              {currentPage} / {Math.ceil(filteredAndSortedItems.length / itemsPerPage)}
            </span>
            <button
              className="page-link"
              disabled={currentPage === Math.ceil(filteredAndSortedItems.length / itemsPerPage)}
              onClick={() => paginate(currentPage + 1)}
            >
            &raquo;
            </button>
          </div>
          <div className="footer-links">
            <a href='https://www.i-motion.de/Homepage/Datenschutzerklaerung'>Datenschutzerklärung</a>
            <span> | </span>
            <a href='https://i-motion.de/Homepage/Impressum'>Impressum</a>
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
