import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://i-idp.i-motion.de',
  realm: 'i-motion-mitarbeiter',
  clientId: 'ticket-dashboard',
};

const keycloak = new Keycloak(keycloakConfig);

keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
  if (authenticated) {
    const email = keycloak.tokenParsed.email;
    const token = keycloak.token;

    sessionStorage.setItem('userEmail', email);
    sessionStorage.setItem('userToken', token);

    // Use createRoot from react-dom/client to render your protected React app in React 18
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
}).catch((error) => {
  console.error('Error initializing Keycloak:', error);
});

reportWebVitals();
